// extracted by mini-css-extract-plugin
export var CTA = "main-module--CTA--1bPji";
export var CTABlack = "main-module--CTABlack--0xzfL";
export var SVGContainerDown = "main-module--SVGContainerDown--PS3ym";
export var SVGContainerTop = "main-module--SVGContainerTop--zQ4OD";
export var blackSectionBottom = "main-module--blackSectionBottom--2Qs53";
export var blackSectionTop = "main-module--blackSectionTop--9eVVX";
export var colorblocks = "main-module--colorblocks--Qb6RE";
export var containerLinks = "main-module--containerLinks--+uVqe";
export var containerMain = "main-module--containerMain--KW0C0";
export var lastName = "main-module--lastName--gjIsP";
export var line = "main-module--line--q5zQr";
export var linewhite = "main-module--linewhite--xvC09";
export var nav = "main-module--nav--XydjB";
export var redDot = "main-module--redDot--FPjQf";
export var whiteSection = "main-module--whiteSection---6-+A";